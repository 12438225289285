import {Outlet} from 'react-router-dom'
import {animated, useTransition} from "react-spring";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "./modal/Modal";

function Layout({location, modalActive, setModalActive, modalCallback, setModalCallback}) {
    const transition = useTransition(location, (location) => location.pathname, {
        from: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 1,
        },
    })
    return <>
        <Header setModalActive={setModalActive} setModalCallback={setModalCallback}/>
        {transition.map(({item, props, key})=>(
            <animated.main key={key} style={props} className='main'>
                <Outlet />
            </animated.main>
        ))}
        <Footer />
        <Modal modalActive={modalActive} setModalActive={setModalActive} modalCallback={modalCallback} setModalCallback={setModalCallback}/>
    </>
}

export {Layout}
