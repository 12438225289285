import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import logo from '../img/logo.svg'
import call from '../img/icons/call.svg'
const Header = ({setModalActive, setModalCallback}) => {
    const [activeBurger, setActiveBurger] = useState(false);

    const openModal = (e) => {
        e.preventDefault()
        setModalCallback(true)
        setModalActive(true)
    }

    return <header className="header" id="home" >
        <div className="container flex-container header-container">
            <div className="header__brand">
                <NavLink to="/" className="flex-container header__brand_link link" id="homeBtn">
                    <img src={logo} alt="OSK" />
                    <p>ОСК</p>
                </NavLink>
            </div>
            <a href="tel:8 (812) 507-85-87" className='call'>
                <img src={call} alt="OSK"/>
            </a>
            <div className={activeBurger ? 'header__burger active' : 'header__burger'}
                 onClick={() => setActiveBurger(!activeBurger)}>
                <span />
            </div>
            <div className={activeBurger ? 'header__navigation active' : 'header__navigation'}>
                <nav className="navigation">
                    <ul className="menu">
                        <li><NavLink to="/" className="home-link" onClick={() => setActiveBurger(!activeBurger)}>Главная</NavLink></li>
                        <li><NavLink to="/chemical" id="chemical-link" onClick={() => setActiveBurger(!activeBurger)}>Химия</NavLink></li>
                        <li><NavLink to="/about" id="about-link" onClick={() => setActiveBurger(!activeBurger)}>О компании</NavLink></li>
                        <li><NavLink to="/contacts" id="contact-link" onClick={() => setActiveBurger(!activeBurger)}>Контакты</NavLink></li>
                        <button className="btn" onClick={e=>openModal(e)}>Перезвоните мне</button>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
};

export default Header;