import React from 'react';
import Helmet from "react-helmet";
import useMatchMedia from "use-match-media-hook";
import backgroundTablet from "../img/wheels-tablet.svg";
import background from "../img/wheels-desk.svg";

const About = ({setModalActive}) => {
    const openModal = (e) => {
        e.preventDefault()
        setModalActive(true)
    }
    const queries = ['(max-width: 1023.9px)']
    const [tablet] = useMatchMedia(queries);
    return <>
        <Helmet >
            <html lang="ru" />
            <title>О компании - ОСК</title>
            <meta name='description' content="Компания ОСК является надежным поставщиком оборудования и запчастей зарубежных производителей,
                        спецтехники и промышленной химии. Накопленный 20-летний опыт работы с крупными поставками и клиентами
                        позволил нам добиться эффективного и надежного результата в области логистики,
                        таможенного оформления в современных условиях и создать уникальную базу клиентов."/>
        </Helmet>
        <div className='about-page'>
            <div className="container flex-container">
                <div className="content">
                    <h1>О компании</h1>
                    <p>
                        Компания ОСК является надежным поставщиком оборудования и запчастей зарубежных производителей,
                        спецтехники и промышленной химии. Накопленный 20-летний опыт работы с крупными поставками и клиентами
                        позволил нам добиться эффективного и надежного результата в области логистики,
                        таможенного оформления в современных условиях и создать уникальную базу клиентов.
                        Направления нашей деятельности:
                    </p>
                    <p><b>Оборудование:</b></p>
                    <ul>
                        <li>Оборудование зарубежных производителей;</li>
                        <li>Современная спецтехника зарубежного производства;</li>
                        <li>Запчасти для импортной техники;</li>
                        <li>
                            Материалы лабораторного, аналитического, геодезического,
                            медицинского и испытательного оборудования, контрольно-измерительных приборов.
                        </li>
                    </ul>
                    <p><b>Промышленная химия:</b></p>
                    <ul>
                        <li>Поставка промышленной химии от с зарубежных заводов-производителей;</li>
                        <li>Сырье для: энергетики, водоподготовки, водоочистки, очистки нефтепродуктов, дождевых сточных вод, спирто-содержащих напитков;</li>
                        <li>Сырье для: трансформаторной, нефтегазовой промышленности;</li>
                        <li>Поставка фармацевтики.</li>
                    </ul>

                    <p>
                        Компания имеет в своем штате опытных и квалифицированных менеджеров,
                        которые разработают и обеспечат поставку химических продуктов на Ваше предприятие,
                        а также предоставят консультативную информацию по применению требуемых химических продуктов в производстве.
                    </p>
                    <button className='btn' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
                <div className="image">
                    {tablet ? <img src={backgroundTablet}/> : <img src={background}/> }
                </div>
            </div>
        </div>
    </>
};

export default About;