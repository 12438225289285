import React from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import Header from "../components/Header";


function NotFound() {

    return <>
        <Helmet >
            <html lang="ru" />
            <title>404</title>
            <meta name='description' content="404"/>
        </Helmet>
        <Header />
        <div className="content__404">
            <div className="container container__404 flex-container" >
                <h1 id='data-aos' data-aos="fade-right">Упс, <br/>Такая страница не существует или находится в разработке...</h1>
                <Link className="btn btn__404 btn__blue" to='/' id='data-aos' data-aos="fade-left">На главную</Link >
            </div>
        </div>
    </>
}

export {NotFound};