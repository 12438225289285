import React from 'react';
import people from '../img/icons/people.svg'
import winner from '../img/icons/winner.svg'
import recommendation from '../img/icons/recommendation.svg'
import background from '../img/wheels-desk.svg'
import backgroundTablet from '../img/wheels-tablet.svg'
import Helmet from "react-helmet";
import useMatchMedia from "use-match-media-hook";

const Home = ({setModalActive}) => {

    const openModal = (e) => {
        e.preventDefault()
        setModalActive(true)
    }
    const queries = ['(max-width: 1023.9px)']
    const [tablet] = useMatchMedia(queries);


    return <>
        <Helmet>
            <html lang="ru" />
            <title>ОСК – Поставщик оборудования, спецтехники, запчастей и промышленной химии</title>
            <meta name='description' content="Компания ОСК является надежным поставщиком оборудования и запчастей зарубежных производителей, спецтехники и промышленной химии.
                        Накопленный 20-летний опыт работы с крупными поставками и клиентами позволил нам добиться эффективного
                        и надежного результата в области логистики, таможенного оформления в современных условиях и создать уникальную базу клиентов."/>
        </Helmet>
        <div className='home-page'>
            <div className="container">
                <div className="content">
                    <h1>Поставщик зарубежного<br/>оборудования, спецтехники, <br/>запчастей и промышленной химии</h1>
                    <p>
                        Компания ОСК является надежным поставщиком оборудования и запчастей зарубежных производителей, спецтехники и промышленной химии.
                        Накопленный 20-летний опыт работы с крупными поставками и клиентами позволил нам добиться эффективного
                        и надежного результата в области логистики, таможенного оформления в современных условиях и создать уникальную базу клиентов.
                    </p>
                    <div className="white-elements">
                        <div className="element">
                            <img src={people} alt="people"/>
                            <p>Универсальная база поставщиков с «честным» ценовым диапазоном</p>
                        </div>
                        <div className="element">
                            <img src={winner} alt="winner"/>
                            <p>Обширное профессиональное портфолио</p>
                        </div>
                        <div className="element">
                            <img src={recommendation} alt="recommendation"/>
                            <p>Реккомендации партнеров</p>
                        </div>
                    </div>
                    <button className='btn' onClick={e=>openModal(e)}>Оставить заявку</button>
                </div>
                <div className="image">
                    {tablet ? <img src={backgroundTablet}/> : <img src={background}/> }
                </div>
            </div>
        </div>
    </>
};

export default Home;