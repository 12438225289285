import React, {useState} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom'
import {Layout} from "./Layout";
import {useTransition} from "react-spring";
import Home from "../pages/Home";
import About from "../pages/About";
import Contacts from "../pages/Contacts";
import {NotFound} from "../pages/NotFound";
import Chemical from "../pages/Chemical";

const AppRouter = () => {
    const [modalActive, setModalActive] = useState(false)
    const [modalCallback, setModalCallback] = useState(false)

    const location = useLocation();


    return <>
            <Routes>
                <Route path='/' element={<Layout location={location} modalActive={modalActive} setModalActive={setModalActive} modalCallback={modalCallback} setModalCallback={setModalCallback}/>} >
                    <Route index element={<Home setModalActive={setModalActive}/>} />
                    <Route path='about' element={<About setModalActive={setModalActive}/>} />
                    <Route path='contacts' element={<Contacts/>} />
                    <Route path='chemical' element={<Chemical/>} />
                </Route>
                <Route path='*' element={<NotFound />} />
            </Routes>
        </>
};

export default AppRouter;