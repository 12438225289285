import React from 'react';

const Footer = () => {
    const currentDate = new Date().getUTCFullYear()
    return <footer>
        <div className="container">
            <p>ⓒ {currentDate}, ООО «ОСК»</p>
        </div>
    </footer>
};

export default Footer;