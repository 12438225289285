import axios from "axios";
import React from "react";

export const sendMail = async (name, email, phone, message, typeSendForm) => {
    let formData = new FormData();
    if (typeSendForm === 'consultationForm'){
        formData.append("email", email);
        formData.append("message", message);
    }
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("typeForm", typeSendForm);

    await axios.post('http://api.ock.spb.ru/sendmail/index.php', formData)
}





