import React from 'react';
import Helmet from "react-helmet";
import './../scss/chemical.scss';

const Chemical = () => {

    const data = [
        {
            id: 0,
            title: 'Нефтедобывающая промышленность',
            desc: 'Используется для приготовления буровых растворов',
            boxList: [
                {
                    title: 'Низковязкая целлюлоза марки SKLV',
                    desc: 'SKLV используется для придания должной текучести буровым растворам, залитым в бурильные трубы, для переноса веществ, поступающих в шлам, на поверхность, для обеспечения водонатяжения шлама и предотвращения утечки буровых растворов в окружающую среду. КМЦ является добавкой, используемой для этой цели. SKLV также хорошо известен как экономичный редуктор потери жидкости API.'
                },
                {
                    title: 'Высоковязкая целлюлоза марки SKHV',
                    desc: 'SKHV- это добавка, используемая для снижения скорости фильтрации API высоковязкого и недорогого бурового раствора. Он подходит для контроля водоотдачи и реологии в суспензиях с низкой твердостью и для очень эффективной вязкости в других осадках'
                },
                {
                    title: 'Полианионная целлюлоза марки',
                    desc: 'SKPACLV используется во многих областях применения в буровых растворах на водной основе; контроль потерь воды, блокатор вязкости и сланца и т. д. Хотя области использования различаются, эта процедура больше фокусируется на контроле фильтрации и вязкостных свойствах. Высокая степень замещения SKPACLV обеспечивает совместимость с другими добавками к буровым растворам.'
                }
            ]
        },
        {
            id: 1,
            title: 'Горнодобывающей промышленности',
            desc: 'Продукция КМЦ создана из специально разработанного полимера целлюлозы для эффективного отделения пустой породы в процессе пенной флотации.\n' +
                'Реагент изменяет гидрофобные свойства пустой породы на гидрофильные, что позволяет увеличить извлечение полезных элементов в процессе пенной флотации.',
            boxList: [
                {
                    title: 'КМЦ для флотации',
                    desc: null,
                    descArr: ['Никелевых руд', 'Платиновых руд', 'Золотоносных руд', 'Калиевых руд', 'Универсальный КМЦ для различных руд.']
                },
            ]
        },
        {
            id: 2,
            title: 'Для производства моющих средств',
            boxList: [
                {
                    title: 'Порошковые КМЦ марки SKTD 6',
                    desc: 'Действует как средство, которое улучшает очищающую способность моющих средств, предотвращая повторное накопление и предотвращая прилипание грязи, уже удаленной синтетическими моющими средствами, к ткани во время стирки.'
                },
                {
                    title: 'Гранулированные КМЦ марки SKTD 6G',
                    desc: 'Контроль вязкости осуществляется за счет использования КМЦ в порошковом моющем средстве, при этом сохраняется больше воды. Рекомендуется полностью растворить SKTD 6 / SKTD 6 PR в воде для лучшего результата во время ввода.'
                },
                {
                    title: 'Кремовый КМЦ марки SKTD 12',
                    desc: 'Действует как средство, которое улучшает очищающую способность моющих средств, предотвращая повторное накопление и предотвращая прилипание грязи, уже удаленной синтетическими моющими средствами, к ткани во время стирки.'
                }
            ]
        },
        {
            id: 3,
            title: 'Для производства красок',
            boxList: [
                {
                    title: 'КМЦ Марки SKTY 70',
                    desc: 'Представляет собой водорастворимый полимер на основе целлюлозы с отличными пленкообразующими свойствами. Благодаря своей целлюлозной структуре он обладает свойством быть очень хорошим клеем для других целлюлозных материалов, таких как бумага. Таким образом, особенность SKTY 70 заключается в том, что он является идеальным клеем для обоев.'
                },
                {
                    title: 'КМЦ Марки SKTY 400',
                    desc: 'Благодаря своей высокой водоудерживающей способности, загущающим свойствам и адгезионным свойствам, SKTY 400 используется для изготовления клея для склеивания обоев.'
                },
                {
                    title: 'КМЦ Марки SKEMH 100',
                    desc: 'Представляет собой водорастворимый полимер на основе целлюлозы с отличными пленкообразующими свойствами. Благодаря своей целлюлозной структуре он является очень хорошим клеем для обоев.'
                },
                {
                    title: 'КМЦ Марки SKTD 2000',
                    desc: 'Используется в качестве клея в обойной промышленности.'
                },
                {
                    title: 'КМЦ Марки SKTD 6000 HV',
                    desc: 'Это водорастворимый полимер на основе целлюлозы, обладающий превосходными пленкообразующими свойствами, влагоудерживающими, связующими и загущающими свойствами. SKTD 6000 HV позволяет контролировать вязкость и удерживать больше воды, а также действует как суспендирующий агент, используется для изготовления обойного клея.'
                },
            ]
        },
        {
            id: 4,
            title: 'Для производства клея',
            boxList: [
                {
                    title: 'КМЦ Марки SKTY 70',
                    desc: 'Представляет собой водорастворимый полимер на основе целлюлозы с отличными пленкообразующими свойствами. Благодаря своей целлюлозной структуре он обладает свойством быть очень хорошим клеем для других целлюлозных материалов, таких как бумага. Таким образом, особенность SKTY 70 заключается в том, что он является идеальным клеем для обоев.'
                },
                {
                    title: 'КМЦ Марки SKTY 400',
                    desc: 'Благодаря своей высокой водоудерживающей способности, загущающим свойствам и адгезионным свойствам, SKTY 400 используется для изготовления клея для склеивания обоев.'
                },
                {
                    title: 'КМЦ Марки SKEMH 100',
                    desc: 'Представляет собой водорастворимый полимер на основе целлюлозы с отличными пленкообразующими свойствами. Благодаря своей целлюлозной структуре он является очень хорошим клеем для обоев.'
                },
                {
                    title: 'КМЦ Марки SKTD 2000',
                    desc: 'Используется в качестве клея в обойной промышленности.'
                },
                {
                    title: 'КМЦ Марки SKTD 6000 HV',
                    desc: 'Это водорастворимый полимер на основе целлюлозы, обладающий превосходными пленкообразующими свойствами, влагоудерживающими, связующими и загущающими свойствами. SKTD 6000 HV позволяет контролировать вязкость и удерживать больше воды, а также действует как суспендирующий агент, используется для изготовления обойного клея.'
                },
            ]
        },
        {
            id: 5,
            title: 'Для загустительных смесей и строительных растворов',
            boxList: [
                {
                    title: 'КМЦ Марки SKTY 300',
                    desc: 'Благодаря своей высокой водоудерживающей способности, загущающим свойствам и адгезионным свойствам, SKTY 300 используется в готовых штукатурках.'
                },
                {
                    title: 'КМЦ Марки SKTD 6000 HV',
                    desc: 'Это водорастворимый полимер на основе целлюлозы, обладающий превосходными пленкообразующими свойствами, влагоудерживающими, связующими и загущающими свойствами. SKTD 6000 HV позволяет контролировать вязкость и удерживать больше воды, а также действует как суспендирующий агент, используется в готовой штукатурке, производстве строительных смесей и комплексных добавок для газо- и пенобетона.'
                },
            ]
        },
        {
            id: 6,
            title: 'Для производства керамики, плитки',
            boxList: [
                {
                    title: 'КМЦ Марки SKEMH 100',
                    desc: 'Представляет собой водорастворимый полимер на основе целлюлозы с отличными пленкообразующими свойствами. Благодаря своей целлюлозной структуре он является очень хорошим связующим компонентом для производства керамического пропана и других промышленных применений. SKEMH 100 можно использовать в качестве клея или адгезивной добавки, отдельно или в смеси с другими полимерами.'
                },
                {
                    title: 'КМЦ Марки SKSY',
                    desc: 'КМЦ с уровнем чистоты 97% получают при экстракции технического типа этиловым спиртом. SKSY широко используется в промышленном секторе, таком как керамика, фарфор.'
                },
            ]
        },
        {
            id: 7,
            title: 'Для производства сварочных электродов',
            boxList: [
                {
                    title: 'КМЦ Марки SKSY',
                    desc: 'КМЦ с уровнем чистоты 97% получают при экстракции технического типа этиловым спиртом. SKSY широко используется в производстве сварочных электродов.'
                },
                {
                    title: 'КМЦ Марки SKTD',
                    desc: 'Благодаря своими связующими и загущающими широко используется в производстве сварочных электродов.'
                },
                {
                    title: 'КМЦ Марки SKTD 6000 HV',
                    desc: 'Это водорастворимый полимер на основе целлюлозы, обладающий превосходными пленкообразующими свойствами, влагоудерживающими, связующими и загущающими свойствами, позволяет контролировать вязкость и удерживать больше воды, а также действует как суспендирующий агент, используется в изготовлении сварочных электродов.'
                },
            ]
        },
        {
            id: 8,
            title: 'Для целюлозно-бумажной промышленности',
            boxList: [
                {
                    title: 'КМЦ Марки SKTD 12',
                    desc: 'Благодаря своими превосходными пленкообразующими свойствами, влагоудерживающими, связующими и загущающими широко используется в производстве бумаги и картона.'
                },
                {
                    title: 'КМЦ Марки SKTY 300',
                    desc: 'Благодаря своей высокой водоудерживающей способности, загущающим свойствам и адгезионным свойствам, SKTY 300 используется в качестве усилителя прочности в бумажной промышленности.'
                },
                {
                    title: 'КМЦ Марки SKZF 1000',
                    desc: 'Благодаря адгезионным свойствам SKZF 1000 он находит широкое применение в декоративной окраске и производстве обоев, благодаря своим функциям загущения, склеивания и изменения вязкости'
                },
                {
                    title: 'КМЦ марки SKTD 1000',
                    desc: 'Благодаря адгезионным свойствам SKTD 1000 он имеет множество применений в декоративной окраске и производстве обоев, благодаря своим функциям загущения, склеивания и изменения вязкости.'
                },
                {
                    title: 'КМЦ Марки SKTD 6000 HV',
                    desc: 'Это водорастворимый полимер на основе целлюлозы, обладающий превосходными пленкообразующими свойствами, влагоудерживающими, связующими и загущающими свойствами. SKTD 6000 HV позволяет контролировать вязкость и удерживать больше воды, а также действует как суспендирующий агент и использоваться в бумажной и текстильной промышленности в качестве усилителя прочности'
                },
            ]
        },
        {
            id: 9,
            title: 'Сырье для: энергетики, водоподготовки, водоочистки, очистки нефтепродуктов, дождевых сточных вод, спирто-содержащих напитков;',
            boxList: []
        },
        {
            id: 10,
            title: 'Сырье для: трансформаторной, нефтегазовой промышленности;',
            boxList: []
        },
        {
            id: 11,
            title: 'Поставка фармацевтики',
            boxList: []
        },
    ]

    return <>
        <Helmet >
            <html lang="ru" />
            <title>Промышленная химия - ОСК</title>
            <meta name='description' content="Одним из направлений является поставка КМЦ (карбоксиметил целюлоза)
                от Турецкого производителя для следующих отраслей производства нефтедобывающей промышленности,
                горнодобывающей промышленности.
                Для производства моющих средств, красок и клея.
                Для загустительных смесей и строительных растворов, для производства керамики, плитки, сварочных электродов.
                Для целюлозно-бумажной промышленности.
                Сырье для: энергетики, водоподготовки, водоочистки, очистки нефтепродуктов, дождевых сточных вод, спирто-содержащих напитков,
                 трансформаторной, нефтегазовой промышленности, Поставка фармацевтики"
            />
        </Helmet>
        <div className='chemical-page'>
            <div className="container">
                <div className="content">

                    <div className="title">
                        <h1>Промышленная химия</h1>
                        <p className='desc'>Поставка промышленной химии с зарубежных заводов-производителей</p>
                        <p className='bold'>Одним из направлений является поставка КМЦ (карбоксиметил целюлоза) от Турецкого производителя для следующих отраслей производства:</p>
                    </div>

                    <div className="content__list">
                        {
                            data.map((box, index) => {
                                return <div key={index} className="content__list_box">
                                    <h2>{box.title}</h2>
                                    <p>{box.desc}</p>
                                    <div key={index} className='box-list'>
                                        {
                                            box.boxList.map((item, itemIndex) => {
                                                return <div className="box-list__item" key={itemIndex}>
                                                    <h3>{box.boxList.length > 1 ? (itemIndex + 1) + '. ' + item.title : item.title}</h3>
                                                    {
                                                        item.desc !== null ? <p>{item.desc}</p>
                                                            : item.descArr && item.descArr.length && item.descArr.map((elem, i) => {
                                                                return <p key={i}>- {elem}</p>
                                                            })
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default Chemical;