import React from 'react';
import mail from "../img/icons/mail.svg";
import phone from "../img/icons/telephone.svg";
import address from "../img/icons/address.svg";
import bg from "../img/contacts-desk.svg";
import Helmet from "react-helmet";

const Contacts = () => {

    return <>
        <Helmet >
            <html lang="ru" />
            <title>Контакты - ОСК</title>
            <meta name='description' content="Контакты компании ОСК. Как проехать до ОСК можно посмотреть на официальном сайте компании."/>
        </Helmet>
        <div className='contacts-page'>
            <div className="container">
                <div className="content">
                    <h1>Контакты</h1>
                    <div className="white-elements">
                        <div className="element">
                            <img src={mail} alt="mail"/>
                            <a href='mailto:Почта:info@ock.spb.ru'>Почта: <br/>info@ock.spb.ru</a>
                        </div>
                        <div className="element">
                            <img src={phone} alt="phone"/>
                            <a href='tel: 8 (812) 507-85-87'>Телефон: <br/> 8 (812) 507-85-87</a>
                        </div>
                        <div className="element">
                            <img src={address} alt="address"/>
                            <p>Адрес: <br/>
                                198035, г. Санкт-Петербург, улица Двинская, дом 10 корпус 3 литер А, офис 823
                            </p>
                        </div>
                        <iframe
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae0494077aa7de36b69bcca845114e15812a64386edb1abbac1d227387bda3396&amp;source=constructor"
                            frameBorder="0"
                        />
                    </div>
                </div>
                <div className="image">
                    <img src={bg} />
                </div>
            </div>
        </div>
    </>
};

export default Contacts;